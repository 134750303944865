jQuery(document).ready(function () {
	jQuery("body").removeClass("hidden");

	jQuery(".image-click").on("click", (event) => {
		console.log("Clicking image");
		console.log(event);
		jQuery(".slick-slider-wrapper").addClass("p-10 inset-0 fixed bg-white");
		jQuery(".slick-slider-wrapper").removeClass("mt-4 mb-4");
		jQuery("body").addClass("overflow-hidden h-screen");

		jQuery(".slick-slider-item").slick("setOption", "slidesToShow", 1);
		jQuery(".slick-slider-item").slick("setOption", "slidesToScroll", 1);
		jQuery(".lightbox-close").removeClass("hidden");

		if (event.currentTarget.dataset.slickIndex) {
			console.log(event.currentTarget.dataset);
			jQuery(".slick-slider-item").slick(
				"slickGoTo",
				event.currentTarget.dataset.slickIndex,
				true
			);
		}

		jQuery(".slick-slider-item").find(".slick-slide").height("auto");
		jQuery(".slick-slider-item").slick("setOption", "", "", true);

		// jQuery(".lightbox").removeClass("hidden");
		// jQuery(".lightbox-item").addClass("hidden");

		if (event.currentTarget.dataset.url) {
			console.log(event.currentTarget);
			jQuery(event.currentTarget)
				.find(".toggle-image")
				.addClass("hidden");
			jQuery(event.currentTarget).find("iframe").removeClass("hidden");
			if (jQuery(event.currentTarget).find("iframe")[0]) {
				jQuery(event.currentTarget).find("iframe")[0].src =
					event.currentTarget.dataset.url;
			}
		}
	});

	jQuery(".lightbox-close").on("click", (event) => {
		jQuery(".slick-slider-wrapper").removeClass(
			"p-10 inset-0 fixed bg-white"
		);
		jQuery(".slick-slider-wrapper").addClass("mt-4 mb-4");

		jQuery("body").removeClass("overflow-hidden h-screen");
		jQuery(".slick-slider-item").slick("setOption", "slidesToShow", 3);
		jQuery(".slick-slider-item").slick("setOption", "slidesToScroll", 3);
		jQuery(".slick-slider-item").find(".slick-slide").height("auto");
		jQuery(".slick-slider-item").slick("setOption", "", "", true);

		jQuery(".slick-slider-item")
			.find(".toggle-image")
			.removeClass("hidden");
		jQuery(".slick-slider-item").find("iframe").addClass("hidden");
		jQuery(".slick-slider-item").find("iframe").attr("src", "");
		jQuery(".lightbox-close").addClass("hidden");
	});

	jQuery(document).on("keyup", function (e) {
		if (e.key == "Escape") {
			jQuery(".slick-slider-wrapper").removeClass(
				"p-10 inset-0 fixed bg-white"
			);
			jQuery(".slick-slider-wrapper").addClass("mt-4 mb-4");

			jQuery("body").removeClass("overflow-hidden h-screen");

			if (window.innerWidth > 650) {
				jQuery(".slick-slider-item").slick(
					"setOption",
					"slidesToShow",
					3
				);
				jQuery(".slick-slider-item").slick(
					"setOption",
					"slidesToScroll",
					3
				);
			}
			jQuery(".slick-slider-item").find(".slick-slide").height("auto");
			jQuery(".slick-slider-item").slick("setOption", "", "", true);

			jQuery(".slick-slider-item")
				.find(".toggle-image")
				.removeClass("hidden");
			jQuery(".slick-slider-item").find("iframe").addClass("hidden");
			jQuery(".slick-slider-item").find("iframe").attr("src", "");
			jQuery(".lightbox-close").addClass("hidden");
		}
	});

	jQuery(".slick-slider-item").slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		// adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	jQuery(".slick-slider-item").on("beforeChange", function () {
		jQuery(".slick-slider-item")
			.find(".toggle-image")
			.removeClass("hidden");
		jQuery(".slick-slider-item").find("iframe").addClass("hidden");
		jQuery(".slick-slider-item").find("iframe").attr("src", "");
	});
});
